<template>
  <el-container>
    <Loading :show="false" />
    <el-header>
      <div class="head-main">
        <div class="head-left">
          <div class="head-logo">
            <img src="../assets/headerLogo.png" alt="" />
          </div>
          <div class="head-title">智能运维平台</div>
          <el-menu
            router
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            background-color="#2c4e73"
            text-color="#97b3cb"
            active-text-color="#fff"
            @select="handleSelect"
            :ellipsis="false"
          >
            <el-menu-item
              @click="RouterPush(item, $event)"
              v-for="(item, index) in menus"
              :key="index"
              :index="item.link"
              >{{ item.menuName }}</el-menu-item
            >
            <el-menu-item @click="RouterScreen" :key="8" index="/monitorScreen"
              >监控大屏</el-menu-item
            >
            <!-- <el-menu-item
              @click="RouterScreen"
              :key="8"
              index="/monitorScreen"
              >监控大屏</el-menu-item
            > -->
          </el-menu>
        </div>
        <ul class="head-right">
          <li>
            <img src="../assets/bell.png" class="bell" alt=""/>
            <!-- <el-popover
              placement="bottom-end"
              popper-class="msgpopover"
              width="400"
              v-model="msgVisible"
              @show="openMsg"
              trigger="click">
              <el-badge slot="reference" class="item">
                <img src="../assets/bell.png" class="bell" alt=""/>
              </el-badge>
              <el-card class="msgcard" shadow="never">
                <div slot="header" class="msgheader clearfix">
                  <el-button type="text" style="color: #333333;">一键已读</el-button>
                  <el-button type="text" style="color: red;">一键清空</el-button>
                </div>
                <div class="msgcnt">
                  <div class="todo">
                    <div class="msgitem" v-for="item in msgData.todo" :key="item.id">
                      <div class="msgitem-title">{{ item.title }}</div>
                      <div class="msgitem-cnt">{{ item.content }}</div>
                      <div class="msgitem-btn">
                        <el-button type="text" @click="handlemsg(item.url)">立即查看</el-button>
                        <el-button type="text" style="color: red;">删除</el-button>
                      </div>
                    </div>
                  </div>
                  <el-divider><span style="">历史记录</span></el-divider>
                  <div class="done">
                    <div class="msgitem" v-for="item in msgData.done" :key="item.id">
                      <div class="msgitem-title">{{ item.title }}</div>
                      <div class="msgitem-cnt">{{ item.content }}</div>
                      <div class="msgitem-btn">
                        <el-button type="text" style="color: #aaaaaa;">删除</el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </el-card>
            </el-popover> -->
          </li>
          <li>
            <img src="../assets/head2.png" class="bell" alt="" @click="playVoice"/>
          </li>
          <li>
            <img src="../assets/head3.png" class="bell" alt="" @click="toSet" />
          </li>
          <li>
            <img
              src="../assets/head4.png"
              class="bell"
              alt=""
              @click="logout"
            />
          </li>
        </ul>
      </div>
    </el-header>
    <el-main>
      <RouterView />
    </el-main>
    <mapDialog ref="mapDialog" @getMapValue="getMapValue"></mapDialog>
  </el-container>
</template>
<script>
// import { flexAble } from "../utils/flexAble.js";
import Loading from "../components/Loading.vue";
import mapDialog from "./MapNavigation/components/mapDialog.vue";

const synth = window.speechSynthesis // 启用文本
const msg = new SpeechSynthesisUtterance()

export default {
  name: "Index",
  components: {
    Loading,
    mapDialog,
  },
  computed: {
    userMenus() {
      return this.$store.state.menus;
    },
  },
  mounted() {
    // flexAble(window, window["lib"] || (window["lib"] = {}));
    this.changeActiveIndex();
    this.getMenuList();
  },
  data() {
    return {
      activeIndex: "/institution",
      menus: [],
      flag: true,
      mapValue: "",
      msgVisible:false,//站内信显示
      msgData: {
        todo: [],
        done: []
      }
      // msgData: {
      //   todo:[
      //     {
      //       title: '审批提醒',
      //       content: '您有一条新的审批待处理，请尽快处理！',
      //       url: '/auditManagement',
      //       id: 1,
      //     },
      //     {
      //       title: '审批提醒',
      //       content: '您有一条新的审批待处理，请尽快处理！您有一条新的审批待处理，请尽快处理！您有一条新的审批待处理，请尽快处理！',
      //       url: '/auditManagement',
      //       id: 2,
      //     },
      //     {
      //       title: '缴费通知',
      //       content: '您有一条新的缴费记录，请注意查收！',
      //       url: '/expenseManagement',
      //       id: 3,
      //     }
      //   ],
      //   done:[
      //     {
      //       title: '审批提醒',
      //       content: '您有一条新的审批待处理，请尽快处理！',
      //       url: '/auditManagement',
      //       id: 1,
      //     },
      //     {
      //       title: '缴费通知',
      //       content: '您有一条新的缴费记录，请注意查收！',
      //       url: '/expenseManagement',
      //       id: 2,
      //     }
      //   ]
      // },
    };
  },
  methods: {
    logout() {
      this.$router.push("/login");
    },
    openMsg() {
      // this.msgVisible = false
      //
    },
    toSet() {
      this.$router.push("/set/set");
    },
    //处理站内信
    handlemsg(url){
      this.$router.push({ path: url })
      this.msgVisible = false
    },
    playVoice(){
      this.handleSpeak('')
    },
    // 语音播报的函数
    handleSpeak(text) {
      msg.text = text // 内容
      msg.lang = 'zh-CN' // 使用的语言:中文
      msg.volume = 1 // 声音音量：1
      msg.rate = 1 // 语速：1
      msg.pitch = 1 // 音高：1
      synth.speak(msg) // 播放
    },
    // 语音停止
    handleStop(e) {
      msg.text = e
      msg.lang = 'zh-CN'
      synth.cancel(msg) // 取消该次语音播放
    },
    changeActiveIndex() {
      const path = this.$route.path.split("/")[1];
      const formattedPath = "/" + path;
      this.activeIndex = formattedPath;
    },
    RouterPush(item, e) {
      if (item.link == "/map") {
        this.$refs.mapDialog.up();
      } else {
        console.log("item.link", item.link);
      }
      // console.log(item.link);
    },
    getMapValue(value) {
      this.mapValue = value;
    },
    RouterScreen() {
      this.$router.push("/monitorScreen");
    },
    handleSelect(key, keyPath) {
      console.log("this.mapValue", this.mapValue);
      // this.activeIndex = key;
    },
    // 获取请求菜单
    async getMenuList() {
      var that = this;
      var loginName = localStorage.getItem("loginName");
      //存储超时，跳转到登陆页
      if (loginName == null || loginName == "") {
        //用户名不存在跳转登陆
        that.$router.push({ path: "/login" });
        return;
      }
      this.menus = this.userMenus;
      //   getUserMenu({userCode:JSON.parse(localStorage.getItem('userInfo')).userCode}).then(res=>{
      //   this.menus=res.data.data;

      // })
    },
  },
};
</script>
<style scoped lang="less">
.msgpopover{
  padding: 5px;
  .msgcard{
    padding: 5px;
    border: none;
    box-shadow: none !important;
    /deep/.el-card__header{
      padding: 4px 12px;
    }
    /deep/.el-card__body{
      padding: 3px;
    }
    /deep/.el-button{
      padding-left: 0;
      padding-right: 0;
    }
    .msgheader{
      display: flex;
      justify-content: flex-end;
    }
    .msgcnt{
      max-height: 400px;
      overflow-x: hidden;
      overflow-y: auto;
      .flex-column{
        display: flex;
        flex-direction: column;
      }
      .msgitem{
        padding: 16px;
        margin-bottom: 5px;
      }
      .msgitem-cnt{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .msgitem-cnt2{
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      .msgitem-btn{
        display: flex;
        justify-content: flex-end;
      }
      .todo{
        .flex-column;
        .msgitem-title{
          // color: #df1d1d;
          position: relative;
          overflow: visible;
          &::before{
            content: '';
            display: block;
            position: absolute;
            top: 3px;
            left: -15px;
            width: 8px;
            height: 8px;
            border-radius: 4px;
            background-color: red;
          }
        }
      }
      .done{
        .flex-column;
        color: #aaaaaa;;
      }
    }
  }
}
.el-container {
  height: 100%;
  overflow: hidden;
}

.mian {
  padding: 0;
}
// .el-main {
//   padding: 0;
//   // height: calc(100vh * 1.25);
//   height: calc(100vh - 60px);

//   // background-color: red;
//   // background-color: transparent;
// }
.el-main {
  padding: 0;
  height: calc(100vh - 85px);
}
.el-main {
  padding: 0;
}

.el-header {
  padding-left: 0;
  box-sizing: border-box;
  padding-right: 10px;
  height: 85px !important;
  background: rgba(44, 78, 115, 1);
}
.head-main {
  height: 100%;
  display: flex;
  justify-content: space-between;
  .head-left {
    display: flex;
    height: 100%;
    .head-logo {
      height: 100%;
      margin: 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 200px;
        padding-top: 10px;
        box-sizing: border-box;
      }
    }
    .head-title {
      margin-left: 10px;
      height: 100%;
      color: #fff;
      font-size: 18px;
      display: flex;
      align-items: center;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        display: block;
        width: 1px;
        height: 30%;
        left: -10px;
        top: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
      }
    }
  }
  .el-menu--horizontal.el-menu {
    border-bottom: none;
    display: flex;
    align-items: center;
    height: 100%;
    .el-menu-item {
      border-bottom: none;
      position: relative;
      font-size: 20px;
      &:hover {
        background: rgb(35, 62, 92) !important;
      }
    }
    .el-menu-item.is-active {
      border-bottom: none;
      background: #2c4e73;//rgba(44, 78, 115, 1);
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: calc(100% - 28px);
        height: 0.04028rem;
        background: #00b2e6;
        bottom: 0.05rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .head-right {
    margin: 0;
    height: 100%;
    display: flex;
    li {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 10px;
      .bell {
        width: 30px;
        cursor: pointer;
      }
    }
  }
}
</style>
